@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @keyframes fall {
    0% {
      transform: translateY(0) rotate(var(--rotation));
      opacity: 1; /* Fully visible at the start */
    }
    80% {
      opacity: 1; /* Fully transparent at the end */
    }
    95% {
      opacity: 0; /* Fully transparent at the end */
    }
    100% {
      transform: translateY(100vh) rotate(var(--rotation));
      opacity: 0; /* Fully transparent at the end */
    }
  }

  .animate-fall {
    animation-name: fall;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
}

body {
  overflow-x: hidden;
}
